<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="people-fill" />
        {{ $t("homeOrg.users") + organization.displayName }}
      </h1>
      <div>
        <router-link to="/admin/organizations">
          <b-button
            class="mr-2"
            data-cy="org-back-button"
            pill
            variant="primary"
          >
            <b-icon-arrow90deg-left class="mr-2" />
            {{ $t("back") }}
          </b-button>
        </router-link>
        <router-link
          :to="{ path: `/admin/users/new`, query: { orgId: $route.params.id } }"
        >
          <b-button variant="primary" pill>
            <b-icon-person-plus class="mr-2" />
            {{ $t("users.newUserButton") }}
          </b-button>
        </router-link>
      </div>
    </div>

    <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
      <b-table
        :busy="loadingUsers"
        :current-page="currentPage"
        :fields="fields"
        :head-variant="'light'"
        :items="users"
        :per-page="0"
        class="mb-0"
        fixed
        hover
      >
        <template #table-busy>
          <div class="text-center my-2" data-cy="users-loading">
            <b-spinner class="align-middle mr-2" />
            <strong>{{ $t("loading") }}</strong>
          </div>
        </template>

        <template #cell(admin)="data">
          <b-form-checkbox id="checkbox-1" v-model="data.value" disabled />
        </template>

        <template #cell(empty)="data">
          <div class="d-flex justify-content-end">
            <b-button
              v-b-modal.modal-center
              :disabled="data.item.email === disableDelete"
              class="ml-2"
              data-cy="delete-org-user"
              pill
              size="sm"
              variant="outline-danger"
              @click="deleteModal(data.item.id)"
            >
              <b-icon-trash />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-if="total > pageSize"
        v-model="currentPage"
        :per-page="pageSize"
        :total-rows="total"
        align="right"
        class="mb-0 mt-3"
        first-text="First"
        last-text="Last"
        next-text="Next"
        prev-text="Prev"
      />

      <b-card-text
        v-if="users.length === 0 && !loadingUsers"
        class="text-center my-4"
      >
        {{ $t("orgs.emptyUsersTable") }}
      </b-card-text>
    </b-card>

    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h4 class="mb-0">
        <b-icon class="mr-2" icon="people-fill" />
        {{ $t("orgs.trustedExternalUsers") }}
      </h4>
    </div>

    <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
      <b-table
        :busy="loadingAssociates"
        :current-page="currentPageAssociates"
        :fields="fields"
        :head-variant="'light'"
        :items="associates"
        :per-page="0"
        class="mb-0"
        fixed
        hover
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2" />
            <strong>{{ $t("loading") }}</strong>
          </div>
        </template>

        <template #cell(admin)="data">
          <b-form-checkbox id="checkbox-1" v-model="data.value" disabled />
        </template>

        <template #cell(empty)="">
          <div class="d-flex justify-content-end">
            <b-button
              v-b-modal.modal-center
              class="ml-2"
              disabled
              pill
              size="sm"
              variant="outline-danger"
            >
              <b-icon-trash />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-if="totalAssociates > pageSize"
        v-model="currentPageAssociates"
        :per-page="pageSize"
        :total-rows="totalAssociates"
        align="right"
        class="mb-0 mt-3"
        first-text="First"
        last-text="Last"
        next-text="Next"
        prev-text="Prev"
      />
      <b-card-text
        v-if="associates.length === 0 && !loadingAssociates"
        class="text-center my-4"
      >
        {{ $t("orgs.emptyUsersTable") }}
      </b-card-text>
    </b-card>

    <b-overlay :opacity="0.95" :show="loadingAccess" rounded="sm">
      <b-card
        border-variant="light"
        class="shadow-sm card-rounded mt-4"
        :title="$t('singleOrgAdmin.trustedOrg')"
      >
        <b-form-group class="mb-0">
          <b-form-tags
            id="tags-with-dropdown"
            v-model="orgAccess"
            no-outer-focus
            size="lg"
            tag-pills
          >
            <template v-slot="{ tags, disabled, addTag, removeTag }">
              <ul v-if="tags.length > 0" class="list-inline d-inline-block">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag
                    :data-cy="`trusted-organizations-tag-${toText(tag)}`"
                    :title="toText(tag)"
                    variant="success"
                    @remove="onRemoveClick(tag, removeTag)"
                  >
                    {{ toText(tag) }}
                  </b-form-tag>
                </li>
              </ul>

              <b-dropdown
                block
                data-cy="trusted-organizations-dropdown"
                menu-class="w-100"
                size="sm"
                variant="outline-secondary"
              >
                <template #button-content>
                  <b-icon class="mr-2" icon="building" />
                  {{ $t("singleOrgAdmin.addOrg") }}
                </template>
                <b-dropdown-form @submit.stop.prevent="() => {}">
                  <b-form-group
                    :description="searchDesc"
                    :disabled="disabled"
                    :label="$t('singleOrgAdmin.searchOrgs')"
                    class="mb-0"
                    label-cols-md="auto"
                    label-for="tag-search-input"
                    label-size="sm"
                  >
                    <b-form-input
                      id="tag-search-input"
                      v-model="search"
                      autocomplete="off"
                      size="sm"
                      type="search"
                    />
                  </b-form-group>
                </b-dropdown-form>
                <b-dropdown-divider />
                <b-dropdown-item-button
                  v-for="option in availableOptions"
                  :key="option.id"
                  :data-cy="`trusted-organization-${option.trustee}`"
                  @click="onOptionClick({ option, addTag })"
                >
                  {{ option.trustee }}
                </b-dropdown-item-button>
                <b-dropdown-text v-if="availableOptions.length === 0">
                  {{ $t("singleOrgAdmin.orgsEmpty") }}
                </b-dropdown-text>
              </b-dropdown>
            </template>
          </b-form-tags>
        </b-form-group>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "SingleorganizationAdmin",
  data() {
    return {
      users: [],
      associates: [],
      organization: {
        name: "",
        id: "",
        displayName: "",
        trustees: [],
      },
      pageSize: 10,
      total: null,
      totalAssociates: null,
      currentPage: 1,
      currentPageAssociates: 1,
      fields: [
        { key: "name", label: this.$i18n.t("orgs.username") },
        { key: "email", label: this.$i18n.t("email") },
        {
          key: "admin",
          label: "Admin",
          thStyle: { width: "80px", minWidth: "80px !important" },
        },
        {
          key: "empty",
          label: "",
          thStyle: { width: "50px", minWidth: "50px !important" },
        },
      ],
      loadingOrg: true,
      loadingUsers: true,
      loadingAssociates: true,
      loadingAccess: true,
      disableDelete: this.$store.getters.email,
      orgAccess: [],
      search: "",
      options: [],
      value: [],
    };
  },
  async mounted() {
    await this.orgProvider();

    const orgUsers = await this.userProvider(false);
    this.total = orgUsers.total;
    this.users = orgUsers.content;

    const associates = await this.userProvider(true);
    this.totalAssociates = associates.total;
    this.associates = associates.content;
  },
  methods: {
    // get users of org
    async userProvider(associates) {
      associates ? (this.loadingAssociates = true) : (this.loadingUsers = true);
      try {
        const {
          data: { content, page },
        } = await this.$http.get(
          this.$cfg.BASE_IDENTITY_URL +
            `/v0/tenants/${this.$route.params.id}/accounts?associates=${associates}`,
          {
            params: {
              page: associates
                ? this.currentPageAssociates - 1
                : this.currentPage - 1,
              pageSize: this.pageSize,
            },
          }
        );

        // checking if user is an admin
        content.forEach((user) => {
          user.roles?.forEach((role) => {
            if (role.substr(0, role.indexOf("@")) === "admin") {
              user.admin = true;
            }
          });
        });

        return {
          total: page.totalElements,
          content: content,
        };
      } catch (err) {
        console.log(err);
        if (err.response) console.log(err.response);
      } finally {
        associates
          ? (this.loadingAssociates = false)
          : (this.loadingUsers = false);
      }
    },

    async orgProvider() {
      this.loadingOrg = true;
      try {
        const {
          data: { name, displayName, id, trustedTenantIds },
        } = await this.$http.get(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/tenants/${this.$route.params.id}`
        );

        this.organization.name = name;
        this.organization.displayName = displayName;
        this.organization.id = id;
        this.organization.trustees = trustedTenantIds;
        this.loadingOrg = false;
        await this.orgsProvider();
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    async orgsProvider() {
      this.loadingAccess = true;
      try {
        const {
          data: { content },
        } = await this.$http.get(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/tenants`,
          {
            params: {
              pageSize: 10000,
              excludeUserCount: true,
            },
          }
        );

        content.forEach((el) => {
          if (el.id !== this.$route.params.id) {
            this.options.push({
              id: el.id,
              trustee: el.displayName,
            });
          }

          if (
            this.organization.trustees?.find((trusetee) => trusetee === el.id)
          ) {
            this.orgAccess.push({
              id: el.id,
              trustee: el.displayName,
            });
          }
        });
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loadingAccess = false;
      }
    },

    // add tag on click
    async onOptionClick({ option }) {
      this.loadingAccess = true;
      try {
        await this.$http.post(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/tenants/${this.$route.params.id}/trusts`,
          {
            tenantId: option.id,
          }
        );
        this.options = [];
        this.userAccess = [];
        this.search = "";
        await this.orgProvider();
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    // remove tag
    async onRemoveClick(tag, removeTag) {
      try {
        const option = JSON.parse(tag);
        await this.$http.delete(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/tenants/${this.$route.params.id}/trusts/${option.id}`
        );
        removeTag(tag);
        this.search = "";
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    // navigate to edit user page
    goToEditUser(userName) {
      this.$router.push(
        `/organization/${this.$route.params.id}/user/${userName}`
      );
    },

    // delete a user
    async deleteUser(id) {
      try {
        await this.$http.delete(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/accounts/${id}`
        );
        this.$bvToast.toast("User deleted!", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });

        const orgUsers = await this.userProvider(false);
        this.total = orgUsers.total;
        this.users = orgUsers.content;
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    deleteModal(id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Are you sure that you want to delete this user?", {
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) this.deleteUser(id);
        })
        .catch((err) => {
          if (err.response) console.log(err.response);
          // An error occurred
        });
    },

    toText(tag) {
      return JSON.parse(tag).trustee;
    },
  },

  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },

    availableOptions() {
      const criteria = this.criteria;

      // This is here because this.options (get /trustees) don't have an id
      // but get /acl have an id so if we try to compare the two of them then it would never match,
      // so we won't have the remove option
      const uAcces = this.orgAccess.map((el) => {
        const newU = JSON.parse(el);
        delete newU.id;
        return newU;
      });

      // Filter out already selected options
      const options = this.options.filter(
        (opt) => uAcces.map((el) => el.trustee).indexOf(opt.trustee) === -1
      );

      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.trustee?.toLowerCase().indexOf(criteria) > -1
        );
      }
      // Show all options available
      return options;
    },

    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return this.$i18n.t("singleOrgAdmin.searchEmpty");
      }
      return "";
    },

    mappedTags() {
      return this.orgAccess.map((item) => JSON.parse(item).trustees);
    },
  },
  watch: {
    currentPage: {
      handler: async function () {
        const orgUsers = await this.userProvider(false);
        this.total = orgUsers.total;
        this.users = orgUsers.content;
      },
    },

    currentPageAssociates: {
      handler: async function () {
        const associates = await this.userProvider(true);
        this.totalAssociates = associates.total;
        this.associates = associates.content;
      },
    },
  },
};
</script>
